import { Amplify } from 'aws-amplify'
import awsmobile from './src/aws-exports';

// Configure Amplify with your project settings
Amplify.configure(awsmobile);
export const onClientEntry = () => {
    if (typeof window !== "undefined") {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        (function (w, d, s, u) {
          w.gbwawc = {
            url: u,
            options: {
              waId: "+91 9902580128",
              siteName: "CareAllianz",
              siteTag: "Home Healthcare",
              siteLogo: "https://images.crunchbase.com/image/upload/c_pad,f_auto,q_auto:eco,dpr_1/o6twejxc2r6khow1x4md",
              widgetPosition: "RIGHT",
              triggerMessage: "",
              welcomeMessage: "Welcome to CareAllianz !",
              brandColor: "#25D366",
              messageText: "How Can we Help You?",
              replyOptions: ["Chat with us","",],
            },
          };
          var h = d.getElementsByTagName(s)[0],
              j = d.createElement(s);
          j.async = true;
          j.src = u + "/whatsapp-widget.min.js?_=" + Math.random();
          h.parentNode.insertBefore(j, h);
        })(window, document, "script", "https://waw.gallabox.com");
      `;
      document.body.appendChild(script);
    }
};