/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://fepn2i62rfhm3afokgd6l5kp6m.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-qrxg5xtbwvby3iqdraikqtdt4e",
    "aws_cognito_identity_pool_id": "ap-south-1:cf8c98d7-1493-497c-b5ef-0c851d0b1b72",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_1aYon5Vis",
    "aws_user_pools_web_client_id": "5bgchhuefo5nm9ajo1ch52bp1p",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "careallianzmobileapps3bucket181010-dev",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
